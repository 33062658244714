import './App.css';
import {useEffect, useState} from 'react';
import axios from 'axios';

function App() {
  const [figurines, setFigurines] = useState([]);

  useEffect(() => {
      const fetchData = async () => {
          const result = await axios(
            "https://api.aretino.ru/",
          );
          setFigurines(result.data);
      };
      fetchData();
  }, []);

  return (
      <div className="App">
          {figurines.map(item => (
              <div key={item.figure_id}>
                  Изображение: <img src={"/images/" + item.image}></img>
                  Аватар: <img src={"/images/" + item.avatar}></img>
                  <p>Название: {item.name}</p>
                  <p>Производитель: {item.manufacturer}</p>
                  <p> Материал: {item.material}</p>
                  <p>Масштаб: {item.scale}</p>
                  <p> Тип: {item.type}</p>
                  <p>Дата выпуска: {item.release_date}</p>
                  <p> 18+: {item.horny}</p>
              </div>
          ))}
      </div>
  );
}

export default App;
